html, body {
    padding: 10px;
}

h2 {
    font-size: 20px;
}

.lineWrapper {
    display: grid;
    grid-template-columns: 20% 80%;
}

.lineWrapper2 {
    display: flex;
    flex-direction: row;
}